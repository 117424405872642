export const CLIENT_ID = `NAM`;
export const CMSSYNC_LOGICAPP = `https://syncfunctionproduction.azurewebsites.net/api/CopyFilesToCMS?code=lH3Lxzn1Dq1PyiHTGzW1kj_20qj-5BXp_O2PU6YHSFpdAzFuJfdO4Q==`;
export const BASE_URL_FULCRUMONE_SSO_SERVICE = `https://fulcrumone-sso-service.prod.neutralsportal.namadr.com`;
export const BASE_URL_NAM_HEARINGS_SERVICE = `https://nam-hearings-service.prod.neutralsportal.namadr.com`;
export const BASE_URL_BLOB_SERVICE = `https://fulcrumone-fileblob-service.prod.neutralsportal.namadr.com`;
export const BASE_URL_NAM_HEARINGOFFICERS_SERVICE = `https://nam-hearingofficers-service.prod.neutralsportal.namadr.com`;
export const BASE_URL_FULCRUMONE_NOTIFICATIONS_SERVICE = `https://fone-notifications-svc.prod.neutrals.namadr.com`;
export const BASE_URL_FULCRUMONE_PDFGENERATE_SERVICE = `https://pdf-neutrals.namadr.com`;//`https://pdfgeneratorfulcrumone-uat.azurewebsites.net`;
export const BASE_URL_DEMOGRPAHICS_SERVICE = `https://nam-demographics-service.prod.neutralsportal.namadr.com`;
export const BASE_URL_TIMESHEET_SERVICE = `https://nam-timesheet-service.prod.neutralsportal.namadr.com`;
export const CREATE_DEMOGRPAHICS = `${BASE_URL_DEMOGRPAHICS_SERVICE}/api/Demographics/SaveDemographic`;
export const GET_DEMOGRPAHICS = `${BASE_URL_DEMOGRPAHICS_SERVICE}/api/Demographics/GetDemographicsByCaseCode`;
export const GENERATE_TOKEN = `${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/generateToken`;
export const REFRESH_TOKEN = `${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/token/refresh`;
export const USER_ROLES = `${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/GetUserRole?email=`;
export const CREATE_COMMON_USER = `${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/Users`;
export const CHANGE_PASSWORD = `${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/ChangePassword`;
export const GET_COLLABORATOR = `${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/GetAllUser?role=`;
export const REVOKE = `${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/token/revoke`;
export const FORGOT_PASSWORD = `${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/forgotpassword`;
export const FORGOT_PASSWORD_WITH_CODE = `${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/forgotpasswordwithcode`;
export const CHANGE_PASSWORD_WITH_CODE = `${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/changepasswordwithcode`;
export const CUSTOM_EMAIL_NOTIFICATION = `${BASE_URL_FULCRUMONE_NOTIFICATIONS_SERVICE}/api/customemailnotification`;
export const VERIFY_ACCOUNT_WITH_CODE = `${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/verifyaccountwithcode`;
export const HEARINGS = `${BASE_URL_NAM_HEARINGS_SERVICE}/GetAllHearings`;
export const HearingsByNeutral = `${BASE_URL_NAM_HEARINGS_SERVICE}/HearingsByNeutral`;
export const HEARINGS_BY_NEUTRAL = `${BASE_URL_NAM_HEARINGS_SERVICE}/HearingsByNeutral`;
export const HEARING_DETAILS = `${BASE_URL_NAM_HEARINGS_SERVICE}/HearingDetails`;
export const PMA_DETAILS = `${BASE_URL_NAM_HEARINGS_SERVICE}/PMADetails`;
export const ADD_PMA_DETAILS = `${BASE_URL_NAM_HEARINGS_SERVICE}/AddPMA`;
export const UpdateHearingData = `${BASE_URL_NAM_HEARINGS_SERVICE}/UpdateHearingData`;
export const ALLHEARING_OFFICERS = `${BASE_URL_NAM_HEARINGOFFICERS_SERVICE}/HearingOfficers/GetAllHearingOfficers`;
export const HEARINGOFFICER_BYEMAIL = `${BASE_URL_NAM_HEARINGOFFICERS_SERVICE}/HearingOfficers/GetHearingOfficerByEmail`;
export const HEARINGOFFICER_BYID = `${BASE_URL_NAM_HEARINGOFFICERS_SERVICE}/HearingOfficers/GetHearingOfficerById`;
export const INSERT_FILE = `${BASE_URL_BLOB_SERVICE}/api/insertfile`;
export const INSERT_DECISION_FILE = `${BASE_URL_BLOB_SERVICE}/api/insertdecisionfile`;
export const INSERT_CASE_FILE = `${BASE_URL_BLOB_SERVICE}/api/insertcasefile`;
export const GET_ALL_PENDINGTASKS = `${BASE_URL_NAM_HEARINGS_SERVICE}/GetAllPendingTasks`;
export const GET_PENDINGTASKS_BY_NEUTRAL = `${BASE_URL_NAM_HEARINGS_SERVICE}/PendingHearingsByNeutral`;
export const GET_FILEWITHSAS = `${BASE_URL_BLOB_SERVICE}/api/GetFileBytewithSAS`;
export const GET_DECISION_FILEWITHSAS = `${BASE_URL_BLOB_SERVICE}/api/GetDecisionFileBytewithSAS`;
export const GET_TIMESHEET_FILEWITHSAS = `${BASE_URL_BLOB_SERVICE}/api/GetTimesheetFileByteWithSAS`;
export const GET_MULTIPLE_UPLOADED_FILE = `${BASE_URL_BLOB_SERVICE}/api/GetMultipleUploadFilefromStorage`;
export const GET_PENDINGTASKS_BY_NEUTRAL_EMAIL = `${BASE_URL_NAM_HEARINGS_SERVICE}/GetPendingHearingsByNeutralEmail`;
export const BASE_URL = `https://neutralsportal.namadr.com/`;
export const HTML_TO_PDF = `${BASE_URL_FULCRUMONE_PDFGENERATE_SERVICE}/api/convertstringtohtml`;
export const GET_INVOICEFORNEUTRAL = `${BASE_URL_NAM_HEARINGS_SERVICE}/GetInvoiceForNeutral`;
export const GET_PENDINGCOUNTFORNEUTRAL = `${BASE_URL_NAM_HEARINGS_SERVICE}/PendingHearingsCountByNeutral`;
export const DOCUSIGN_CONNECTOR = `https://docusignconnector.azurewebsites.net/api/DocusignConnector?code=J3OZ57J3vFueRh2F-6kYuDlJ-gV2fofd4HNXBUrP7vJuAzFuHdwj4A==`;
export const GET_ALLPENDING = `${BASE_URL_NAM_HEARINGS_SERVICE}/AllPendingHearings`;
export const UPDATE_PENDINGTASKS = `${BASE_URL_NAM_HEARINGS_SERVICE}/UpdatePendingTask`;
export const GET_CASESUBMISSIONS = `${BASE_URL_NAM_HEARINGS_SERVICE}/GetSubmissionsByCase`;
export const DELETE_RESPONSE = `${BASE_URL_BLOB_SERVICE}/deletefile`;
export const DELETE_PMA_RESPONSE = `${BASE_URL_NAM_HEARINGS_SERVICE}/DeletePMA`;
export const MERGE_PMA_RESPONSE = `${BASE_URL_FULCRUMONE_PDFGENERATE_SERVICE}/api/MergeMultipleFileUpload`;
export const MERGE_DECISIONPMA_RESPONSE = `${BASE_URL_FULCRUMONE_PDFGENERATE_SERVICE}/api/DecisionPamMultipleFileUpload`;
export const GET_INVOICE_STATEMENTS = `${BASE_URL_BLOB_SERVICE}/api/GetStatements`;
export const GET_TRAINING_VIDEOS = `${BASE_URL_BLOB_SERVICE}/api/GetTrainingVideos`;
export const DELETE_PMA_ADDITIONAL_RESPONSE = `${BASE_URL_NAM_HEARINGS_SERVICE}/DeleteAdditionalResponsePMA`;
export const DELETE_ADDITIONAL_RESPONSE = `${BASE_URL_BLOB_SERVICE}/DeleteAdditionalFile`;
export const UPDATE_CMSDEMOGRPAHICS = `https://syncfunctionproduction.azurewebsites.net/api/demographicsSyncing?code=jBE11RGoaYo3_llZoMdTX0PhVfI7WCSxJSEY50rLgPa-AzFuX-YWpg==`
export const UPDATE_CMSTIMESHEET = `https://syncfunctionproduction.azurewebsites.net/api/timesheetSyncing?code=ebb5QpmytzTxiVxyu-nQ_Ja39W7UVFzh60ntBJkPTiabAzFudvOgtA==`;
export const UPDATE_CMSPMA = `https://syncfunctionproduction.azurewebsites.net/api/PmaSyncing?code=q1wnaqlJtNKMa_pjSimX6qk9g5cFzV_ZOlGyZka2J2qjAzFun2Kh_g==`;
export const LOGIN_SYNC = `https://syncfunctionproduction.azurewebsites.net/api/LoginSyncing?code=ckGiw5NPEjrjTG6wVPlfjWn2g3SFFXMP2dmhqT93-eu-AzFufCQr8A==`;
export const CASE_SYNC = `https://pmasync.azurewebsites.net/api/CMSCaseDataSync?code=zz3x7mXj_3zYGenBpjH9s9nOBpUkI6MnxN9MUSVKWTVCAzFuNnWp1Q==`;
export const CMSSYNC = `https://syncfunctionproduction.azurewebsites.net/api/UserDataSync?code=dirx3Oy9Dkji-h1lFF-AoVyDOLEU1arpfWChQIzxp54IAzFu0uhtTw==`;
export const GET_DOCUSIGNFILE = `${BASE_URL_BLOB_SERVICE}/api/GetDocusignFile`;
export const isMask = false;
export const isSyncBack = true;
export const MERGE_MULTIPLE_PDF=`${BASE_URL_FULCRUMONE_PDFGENERATE_SERVICE}/api/mergemultiplefiles`;
export const env = "Prod";
export const BIG_4_NEUTRALS = [];
export const ZOOMMEETING_DETAILS = `${BASE_URL_NAM_HEARINGS_SERVICE}/GetZoomMeetingByHearingCode`;
export const TIMESHEET_DETAILS = `${BASE_URL_TIMESHEET_SERVICE}/GetAllTimesheetDetails`;
export const ADD_TIMESHEET_DETAILS = `${BASE_URL_TIMESHEET_SERVICE}/AddTimesheet`;
export const INSERT_PENDING_TIMESHEET_DETAILS = `${BASE_URL_TIMESHEET_SERVICE}/InsertTimesheetInPendingTask`;
export const UPDATE_PENDING_TIMESHEET_DETAILS = `${BASE_URL_TIMESHEET_SERVICE}/UpdatePendingStatusAsync`;
export const GETTIMESHEET_DETAILS_BY_CASECODE = `${BASE_URL_TIMESHEET_SERVICE}/GetTimesheetDetails`;
export const mailTimesheet = `timesheets@namadr.com`
export const mailSchedule = `scheduling@namadr.com`
export const mailAccount = `accounting@namadr.com`
export const mailMask = `sneha.agashe@fulcrumdigital.com`
export const UPDATE_LOGGEDIN_DETAILS = `${BASE_URL_NAM_HEARINGS_SERVICE}/UpdateLoggedInUserDetails`
export const GRAMERLY_CLIENT_ID = 'client_PkEwy9Ct6LmqLoorGewrtL'
export const EMAILCUSTOMERSERVICE = 'customerservice@namadr.com'
export const PHONECUSTOMERSERVICE = '1-800-358-2550'
export const GRAMERLY_ASSERTION_ID = "https://nam-staging-grammarly.azurewebsites.net/"
export const FORGOT_PASSWORD_EMAIL_SUBJECT="NAM Neutrals Portal Forgot Password";
export const COMMERCIAL_EMAIL_SUBJECT="Timesheet for NAM ID: ${caseReference} submitted for Review";
export const COMMERCIAL_REVIEW_EMAIL_SUBJECT="Timesheet for NAM ID: ${caseReference} clarification required";
export const MASK_PMA_PARTICIPANTS=false;
export const EDIT_NEUTRAL_EMAIL=true;
export const HEARING_DURATION_LIMIT=1380;
export const IDLE_TIMEOUT=1200000;
export const REALTIME_DATA=true;
export const IsMaintenance =false;
export const secKey="b14ca5898a4e4133bbce2ea2315a1916";
export const voidedDocusign="https://docusignconnector.azurewebsites.net/api/VoidEnvelope?code=M8Y2xE1lkSeFDgWPO-4ommdhHp33elzI9t0wTAMOhhNFAzFuseimpw==";
